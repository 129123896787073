import React from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';

import { onboardingComplete, onboardingRemindLater } from '@/components/with_auth/State';
import routes from '@/services/routes';
import Link from '@/components/commons/link';
import styles from '@/components/commons/site_navigation/SiteNavigation.module.css';

const hasTrailingSlash = (path) => path.endsWith('/');

const IN_PROD = process.env.NEXT_PUBLIC_ENV === 'prod';

function HeaderMenu() {
    const router = useRouter();
    const effectivePath = hasTrailingSlash(router.asPath) ? router.asPath : `${router.asPath}/`;
    const isOnboardingComplete = useRecoilValue(onboardingComplete);
    const remindLater = useRecoilValue(onboardingRemindLater);

    // we hide the getting started if user completed all onboarding or it hasn't been a week
    // since they asked to be reminded
    const hideGettingStarted = !(isOnboardingComplete || remindLater);

    return (
        <div className="navOuterWrapper hidden md:flex">
            {!IN_PROD && (
                <ul>
                    <li className={`${styles.headerMenuContainer} submenu`}>
                        <Link
                            to={routes.MY_SIXTY}
                            target="_self"
                            rel="noreferrer"
                            className={styles.headerMenuItem}
                            active={router.asPath.startsWith(routes.MY_SIXTY)}
                            activeClassName={styles.headerMenuItemActive}
                        >
                            My Sixty
                        </Link>
                    </li>
                </ul>
            )}
            {!IN_PROD && (
                <ul>
                    <li className={`${styles.headerMenuContainer} submenu`}>
                        <Link
                            to={routes.MY_NETWORK}
                            target="_self"
                            rel="noreferrer"
                            className={styles.headerMenuItem}
                            active={router.asPath.startsWith(routes.MY_NETWORK)}
                            activeClassName={styles.headerMenuItemActive}
                        >
                            My Network
                        </Link>
                    </li>
                </ul>
            )}
            {(hideGettingStarted && (
                <ul>
                    <li className={`${styles.headerMenuContainer} submenu`}>
                        <Link
                            to={routes.GETTING_STARTED}
                            target="_self"
                            rel="noreferrer"
                            className={styles.headerMenuItem}
                            active={router.asPath.startsWith(routes.GETTING_STARTED)}
                            activeClassName={styles.headerMenuItemActive}
                        >
                            Getting Started
                        </Link>
                    </li>
                </ul>
            )) || (
                <ul>
                    <li className={`${styles.headerMenuContainer} submenu`}>
                        <Link
                            to={routes.ROOT}
                            target="_self"
                            rel="noreferrer"
                            className={styles.headerMenuItem}
                            active={effectivePath === routes.ROOT}
                            activeClassName={styles.headerMenuItemActive}
                        >
                            Insights
                        </Link>
                    </li>
                </ul>
            )}
            <ul>
                <li className={`${styles.headerMenuContainer} submenu`}>
                    <Link
                        to={routes.INBOX}
                        target="_self"
                        rel="noreferrer"
                        className={styles.headerMenuItem}
                        active={router.asPath.startsWith(routes.INBOX)}
                        activeClassName={styles.headerMenuItemActive}
                    >
                        Inbox
                    </Link>

                    <ul className="megamenu">
                        <ul className="flex pl-[64px]">
                            <Link
                                to={routes.MAIL_RULES}
                                target="_self"
                                className={styles.headerSubMenuItem}
                                active={effectivePath === routes.MAIL_RULES}
                                activeClassName={styles.headerSubMenuItemActive}
                            >
                                Rules
                            </Link>
                            <Link
                                to={routes.MONITORED_LABELS}
                                target="_self"
                                className={styles.headerSubMenuItem}
                                active={effectivePath.startsWith(routes.MONITORED_LABELS)}
                                activeClassName={styles.headerSubMenuItemActive}
                            >
                                Smart Folders
                            </Link>
                            <Link
                                to={routes.EMAIL_DOMAINS}
                                target="_self"
                                className={styles.headerSubMenuItem}
                                active={effectivePath.startsWith(routes.EMAIL_DOMAINS)}
                                activeClassName={styles.headerSubMenuItemActive}
                            >
                                Known Domains
                            </Link>
                        </ul>
                    </ul>

                </li>
            </ul>
            <ul>
                <li className={`${styles.headerMenuContainer} submenu`}>
                    <Link
                        to={routes.CALENDAR}
                        target="_self"
                        rel="noreferrer"
                        className={styles.headerMenuItem}
                        active={router.asPath.startsWith(routes.CALENDAR)}
                        activeClassName={styles.headerMenuItemActive}
                    >
                        AI Assistant
                    </Link>
                    <ul className="megamenu">
                        <ul className="flex pl-[64px]">
                            <Link
                                to={routes.AGENDAS}
                                target="_self"
                                className={styles.headerSubMenuItem}
                                active={effectivePath.startsWith(routes.AGENDAS)}
                                activeClassName={styles.headerSubMenuItemActive}
                            >
                                Agendas
                            </Link>

                            <Link
                                to={routes.BIO}
                                target="_self"
                                className={styles.headerSubMenuItem}
                                active={effectivePath === routes.BIO}
                                activeClassName={styles.headerSubMenuItemActive}
                            >
                                Brief
                            </Link>

                            <Link
                                to={routes.ALERTS}
                                target="_self"
                                className={styles.headerSubMenuItem}
                                active={effectivePath.startsWith(routes.ALERTS)}
                                activeClassName={styles.headerSubMenuItemActive}
                            >
                                Smart Alerts
                            </Link>

                            <Link
                                to={routes.BIRTHDAYS}
                                target="_self"
                                className={styles.headerSubMenuItem}
                                active={effectivePath === routes.BIRTHDAYS}
                                activeClassName={styles.headerSubMenuItemActive}
                            >
                                Outreach
                            </Link>

                        </ul>
                    </ul>
                </li>
            </ul>
            <ul>
                <li className={`${styles.headerMenuContainer}`}>
                    <div className={styles.headerSeparator} />
                </li>
            </ul>
            <ul>
                <li className={`${styles.headerMenuContainer}`}>
                    <Link
                        to={routes.CONTACTS_GAME}
                        target="_self"
                        className={styles.headerMenuItem}
                        active={effectivePath.startsWith(routes.CONTACTS_GAME)}
                        activeClassName={styles.headerSubMenuItemActive}
                    >
                        <span className="align-middle inline">
                            {' '}
                            Contacts Game
                        </span>
                    </Link>
                </li>
            </ul>
            <ul>
                <li className={`${styles.headerMenuContainer}`}>
                    <a href={routes.HELP} target="_blank" className={styles.headerMenuItem} rel="noreferrer">
                        <span className="align-middle inline">
                            {' '}
                            Help
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default HeaderMenu;
